<template>
  <div>
    <el-form ref="form" :model="form" inline label-width="110px">
      <el-form-item label="标签">
        <el-cascader ref="queryHandle" v-model="form.labelIdList" collapse-tags filterable :options="tagData" clearable :props=" { multiple: true,checkStrictly: true, value: 'id', label: language === 'en-US' ? 'labelNameEN' : 'labelName', children: 'childNodes' }">
          <template slot-scope="{ data }">
            <span v-if="language === 'en-US'">{{ data.labelNameEN }}</span> <span v-else>{{ data.labelName }}</span>
          </template>
        </el-cascader>
      </el-form-item>
      <el-form-item label="供应商">
        <el-select v-model="form.supplier" filterable clearable>
          <el-option
            v-for="item in vendorOptions"
            :key="item.id"
            :label="item.vendorName"
            :value="item.vendorName"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="定位">
        <el-select v-model="form.positionList" placeholder="请选择" clearable multiple collapse-tags>
          <el-option value="P3" label="P3" />
          <el-option value="P2" label="P2" />
          <el-option value="P1" label="P1" />
          <el-option value="P0" label="P0" />
          <el-option value="新品" label="新品" />
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="2em">
        <el-checkbox
          v-model="form.flag"
        >
          仅显示启用标签
        </el-checkbox>
      </el-form-item>
      <el-form-item label="Style">
        <el-select v-model="form.styleList" value-key="id" clearable filterable multiple collapse-tags>
          <el-option
            v-for="item in styleOptions"
            :key="item.id"
            :label="item.styleName"
            :value="item.styleName"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="选择日期">
        <el-date-picker
          v-model="form.date"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="~"
          :start-placeholder="$t('title.starttime')"
          :end-placeholder="$t('title.endtime')"
          :unlink-panels="true"
          :default-time="['00:00:00', '23:59:59']"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
        <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="handleExport()">导出</el-button>

    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      class="mb-3"
      border
      :data="tableData"
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
      max-height="500px"
    >
      <el-table-column
        prop="labelName"
        label="标签名称"
        align="center"
        sortable
      />
      <el-table-column
        prop="total"
        label="总量"
        align="center"
        sortable
      />
      <el-table-column
        prop="reviewTotal"
        label="评论管理"
        align="center"
        sortable
      />
      <el-table-column
        prop="emailTotal"
        label="邮件管理"
        align="center"
        sortable
      />
    </el-table>
    <el-pagination
      :current-page="pager.current"
      :page-sizes="[20, 50, 100, 200, 300]"
      :page-size="pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pager.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import mixin from '@/mixin/oms-mixin'
import Cookies from 'js-cookie'
import { getAmazonCountLabel, messagesGetLabel, exportAmazonCountLabel } from '@/api/service-management'
import { queryVendorList, queryStyleList } from '@/api/omsorder'
import qs from 'qs'
export default {
  mixins: [mixin],
  data() {
    return {
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      language: '',
      tagData: [],
      TableLoading: false,
      tableData: [],
      form: {
        labelIdList: '',
        flag: '',
        date: [],
        styleList: [],
        supplier: '',
        positionList: []
      },
      labelsList: [],
      styleOptions: [],
      vendorOptions: []
    }
  },

  computed: {
    queryParameter() {
      this.isLabels()
      const [start = '', end = ''] = this.form.date || []
      const { flag } = this.form
      return Object.assign({}, this.form, this.pager, { flag, labelIdList: this.labelsList, start, end })
    },
    exportUrl() {
      return exportAmazonCountLabel + '?' + qs.stringify(this.queryParameter, { arrayFormat: 'repeat' })
    }
  },

  mounted() {
    this._getAmazonCountLabel()
    this._queryVendorList()
    this._queryStyleList()
    this._messagesGetLabel(true)
    this.language = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
  },
  methods: {
    // 获取style数据
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // 获取供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    // 获取标签
    async _messagesGetLabel(params) {
      const { datas } = await messagesGetLabel(params)
      this.tagData = datas
    },
    isLabels() {
      const data = this.$refs['queryHandle'].getCheckedNodes(false)
      const arr = []
      // 选出第二层
      console.log(data)
      data.map(e => {
        console.log(e)
        if (e.level === 1 || e.level === 2) {
          arr.push(e.value)
        }
      })
      this.labelsList = arr
    },
    // 导出文件
    handleExport() {
      window.open(this.exportUrl, '_blank')
    },
    // 点击查询获取信息
    queryClick() {
      this.pager.current = 1
      this._getAmazonCountLabel(this.queryParameter)
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.pager.current = 1
      Object.assign(this.form, this.$options.data.call(this).form)
      this._getAmazonCountLabel(1)
    },
    // 默认查询
    async _getAmazonCountLabel(params) {
      try {
        this.TableLoading = true
        const { datas } = await getAmazonCountLabel(this.queryParameter)
        this.tableData = datas.records
        this.pager = datas.pager
      } finally {
        this.TableLoading = false
      }
    },

    handleSwitch(row) {
      const ids = []
      ids.push(row.id)
      const status = row.wordStatus
      this._updateBatchSensitiveWords(ids, status)
    },
    handleSizeChange(val) {
      this.pager.size = val
      this._getAmazonCountLabel(this.queryParameter)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._getAmazonCountLabel(this.queryParameter)
    }

  }
}
</script>

<style scoped lang="scss">
.mark,
.count {
  color: red;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

/deep/.el-tabs__header {
  margin: 0 !important;
}
.Row-Input {
  height: 38px;
  overflow: hidden;
}
.show-row {
  height: auto;
}
</style>
